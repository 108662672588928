<template>
  <main class="settings">

    <!-- player -->
    <b-container v-if="player">

      <b-form-group :label="$t('language')">
        <b-form-select :options="locales" v-model="locale" />
      </b-form-group>

      <b-button v-if="event && event.teams && event.team_type == 'default'" variant="primary" to="/team">
        {{ $t('change_team') }}
      </b-button>
      <b-button variant="secondary" @click="leave">{{ $t('leave_event') }}</b-button>

      <!-- The settings below are for debugging purposes only -->
      <template v-if="debuggingEnabled">
        <b-form-group label="Name">
          <b-form-input v-model="player.name" />
        </b-form-group>
        <b-button variant="primary" @click="save_player">Save</b-button>
        <b-button variant="danger" @click="change_player">Logout</b-button>

        <hr />

        <b-form-group label="Change to existing player">
          <b-form-input v-model="existingPlayerId" />
        </b-form-group>
        <b-button variant="danger" @click="switchToExistingPlayer">Change to this player</b-button>
      </template>

      <div class="app-info" @click="enableDebugging">
        <template v-if="app_info">v{{ app_info.version }}#{{ app_info.build }}</template>
        <template v-else>Unidentified version</template>
        <template v-if="debuggingEnabled"> | Debugging enabled</template>
      </div>

    </b-container>

  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Player',
  data: function () {
    return {
      locales: [{ value: 'en', text: 'English' }, { value: 'nl', text: 'Nederlands' }],
      debuggingClicks: 0,
      existingPlayerId: 0
    }
  },
  computed: {
    ...mapState(['app_info', 'debuggingEnabled']),
    ...mapState('socket', ['event', 'playerId']),
    ...mapGetters('socket', ['player']),
    ...mapGetters(['isLoading']),

    locale: {
      get() {
        return this.$store.state.locale
      },
      set(val) {
        this.$store.commit('locale', val)
      }
    },
  },
  watch: {
  },
  async mounted() {
    if (this.player == null) {
      this.$store.commit('socket/player', {})
    } else {
      this.existingPlayerId = this.playerId
    }
  },
  components: {
  },
  methods: {
    ...mapActions('socket', ['leave', 'change_player', 'save_player', 'leave_team']),

    switchToExistingPlayer() {
      this.$store.commit('socket/playerId', this.existingPlayerId)
      this.$store.dispatch('socket/join')
    },

    enableDebugging() {
      this.debuggingClicks++;
      if (this.debuggingClicks == 5) {
        this.$store.commit('debuggingEnabled', true)
      }
    }
  }
}
</script>

<style lang="scss">
.app-info {
  position: absolute;
  bottom: 0;
  font-size: 10px;
}
</style>
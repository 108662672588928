import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Settings from '../views/Settings'
import GameDescription from '../views/GameDescription.vue'
import Whoareyou from '../views/Whoareyou.vue'
import Team from '../views/Team.vue'

import Event from '../views/Event.vue'

import RoundList from '../views/event/RoundList.vue'
import Round from '../views/event/Round.vue'
import TaskList from '../views/event/round/TaskList.vue'
import Task from '../views/event/round/Task.vue'

import Help from '../views/event/Help.vue'
import Highscore from '../views/event/Highscores.vue'
import Chat from '../views/event/Chat.vue'
import Map from '../views/event/Map.vue'

import store from '../store'

Vue.use(VueRouter)

let routes = [
  /// EVENT CODE
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'slide-left' },
  },
  /// ABOUT THE APP
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { transition: 'slide-left' },
  },
  /// EDIT PLAYER PROFILE
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { transition: 'slide-left' },
  },
  /// CREATE PLAYER PROFILE
  {
    path: '/whoareyou',
    name: 'Whoareyou',
    component: Whoareyou,
    meta: { transition: 'slide-left' },
  },
  /// CREATE OR JOIN TEAM 
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: { transition: 'slide-left' },
  },
  /// GAME DESCRIPTION
  {
    path: '/gamedescription',
    name: 'GameDescription',
    component: GameDescription,
    meta: { transition: 'slide-left' },
  },

  /// GAME INTRO
  {
    path: '/gameintro',
    name: 'GameIntro',
    component: () => import('../views/game/Intro'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/gameoutro',
    name: 'GameOutro',
    component: () => import('../views/game/Outro'),
    meta: { transition: 'slide-left' },
  },

  /// EVENT
  {
    path: '/event',
    component: Event,
    meta: { transition: 'slide-left' },
    children: [
      {
        path: '',
        component: RoundList,
        meta: { transition: 'slide-left' },
      },
      {
        path: 'rounds',
        name: 'RoundList',
        component: RoundList,
        meta: { transition: 'slide-left' },
      },
      {
        path: 'rounds/:roundId',
        component: Round,
        meta: { transition: 'slide-left' },
        children: [
          {
            path: '',
            component: TaskList,
            meta: { transition: 'slide-left' },
          },
          {
            path: 'tasks',
            name: 'TaskList',
            component: TaskList,
            meta: { transition: 'slide-left' },
          },
          {
            path: 'tasks/:taskId',
            name: 'Task',
            component: Task,
            meta: { transition: 'slide-left' },
          }
        ]
      },

      /// EVENT HELP / INSTRUCTIONS
      {
        path: 'help',
        name: 'Help',
        component: Help,
        meta: { transition: 'slide-left' },
      },
      /// EVENT HIGHSCORES
      {
        path: 'highscore',
        name: 'Highscore',
        component: Highscore,
        meta: { transition: 'slide-left' },
      },

      /// EVENT CHAT
      {
        path: 'chat',
        name: 'Chat',
        component: Chat,
        meta: { transition: 'slide-left' },
      },

      /// EVENT CHAT
      {
        path: 'map',
        name: 'Map',
        component: Map,
        meta: { transition: 'slide-left' },
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

function navigationGuardUserCheck(to, from, next) {
  //navigate to home when no event is found
  // console.log(to)
  /*
    //redirect to home when no event is selected
    if (!store.state.socket.event) {
      //only redirect when not already navigating to home
      if (to.matched.some(route => route.name !== "Home")) {
        next({ name: 'Home' })
      }
    }
  
    //redirect to create-player when no player is set
    else if (!store.state.socket.playerId) {
      //only redirect when not already navigating to player
      if (to.matched.some(route => route.name !== "Player")) {
        next({ name: 'Player' })
      }
    }
  */
  next()
}

router.beforeEach((to, from, next) => {
  if (!store.state.initialized) {
    store.dispatch('init', null, { root: true }).then(() => {
      navigationGuardUserCheck(to, from, next)
    })
  } else {
    navigationGuardUserCheck(to, from, next)
  }
})

router.afterEach((to, from) => {
  const toParams = to.path.split('/')
  const fromParams = from.path.split('/')
  var transitiontype = toParams.length < fromParams.length ? 'slide-right' : 'slide-left'
  const tabPages = ["rounds", "highscore", "help", "chat"]
  // check  if we are in event (sub)page
  if (fromParams.indexOf("event")) {
    var fromIndex = tabPages.indexOf(fromParams[2])
    var toIndex = tabPages.indexOf(toParams[2])
    if (fromIndex != -1 && toIndex != -1 && fromIndex != toIndex) {
      if (fromIndex < toIndex) {
        transitiontype = 'slide-left'
      } else {
        transitiontype = 'slide-right'
      }
    }
  }

  store.commit('transition', transitiontype);
})

export default router

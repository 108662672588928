import router from "../router";

export default {
  namespaced: true,
  state: {
    useConfirm: false,
    confirmMessage: 'Are you sure',
    customBackMethod: null,
    // onConfirm: null,
    // onCancel: null
  },
  mutations: {
    useConfirm(state, val) {
      state.useConfirm = val
    },
    confirmMessage(state, val) {
      state.confirmMessage = val
    },
    customBackMethod(state, val) {
      state.customBackMethod = val
    },
    // onConfirm(state, val) {
    //   state.onConfirm = val
    // },
    // onCancel(state, val) {
    //   state.onCancel = val
    // },
  },
  actions: {
    onPressBack({ commit, state }) {
      if (state.customBackMethod) {
        state.customBackMethod();
        return;
      }
      if (state.useConfirm) {
        if (confirm(state.confirmMessage)) {
          commit('useConfirm', false)
          router.back();
          // if (state.onConfirm) state.onConfirm();
        } else {
          // if (state.onCancel) state.onCancel();
        }
      } else {
        router.back();
      }
    },
  }
}
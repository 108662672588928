<template>

  <main class="whoareyou" v-if="event">

    <!-- Player found from memory -->
    <template v-if="player && playerId && player.id == playerId">
      <b-container>
        <!-- Event without teams, we can continue with the current user -->
        <template v-if="!this.event.teams || (this.event.teams && this.event.team_type == 'default')">
          <h2>{{ $t('welcome_back') }} {{ player.name }}, </h2>
          <b-button variant="primary" :to="this.next_page_url">{{ $t('start') }}</b-button>
          <b-button variant="link" @click="change_player">{{ $t('change_user') }}</b-button>
        </template>

        <template v-else-if="this.event.teams && this.event.team_type == 'hotseat'">
          <template v-if="have_team && team">
            <h2>{{ $t('welcome_back') }} {{ team.name }}, </h2>
            <b-button variant="primary" :to="this.next_page_url">{{ $t('start') }}</b-button>
            <b-button variant="link" @click="change_player">{{ $t('new_team') }}</b-button>
          </template>

          <template v-else>
            <h2>{{ $t('welcome_back') }} {{ player.name }}, </h2>
            <p>{{ $t('hotseat_new_team') }}</p>
            <b-button variant="link" @click="change_player">{{ $t('new_team') }}</b-button>
          </template>
        </template>

      </b-container>
    </template>

    <!-- Create new player -->
    <template v-else>

      <!-- If the event as teams and it a 'hotseat' event -->
      <b-container v-if="event && event.teams && event.team_type == 'hotseat'" id="hotseat">

        <b-form @submit.prevent="create_hotseat_team(hotseat)">
          <mediaupload label="" accept="image/*" icon="camera" v-model="hotseat.avatarId" />
          <b-form-group :label="$t('create_new_team_hotseat')">
            <b-form-input v-model="hotseat.name" required />
          </b-form-group>

          <h3 class="my-4">{{ $t('who_are_you_hotseat') }}</h3>

          <b-form-group v-for="(p, idx) in hotseat.players" :label="`${$t('player')} ${(idx + 1)}`"
            :key="`hotseat_player_${idx}`">
            <b-input-group>
              <b-form-input :id="`playername${idx}`" :name="`playername${idx}`" v-model="p.name" required
                @change="checkDuplicateName" />

              <b-input-group-append v-if="idx > 0">
                <b-button variant="primary btn-icon" @click="hotseat.players.splice(idx, 1); checkDuplicateName()">
                  <font-awesome-icon :icon="['fas', 'trash']" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

          </b-form-group>

          <b-button variant="primary btn-icon" id="add-player" @click="hotseat.players.push({}); checkDuplicateName()">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </b-button>

          <div class="form-group mt-4">
            <b-form-checkbox id="terms" name="terms" required>{{ $t('agree_with_terms_and_conditions') }} <b-link
                v-b-modal.terms-modal>{{ $t('terms_and_conditions') }}</b-link>
            </b-form-checkbox>
          </div>

          <div class="form-group mb-4">
            <b-form-checkbox id="privacy" name="privacy" required>{{ $t('agree_with_privacy_policy') }} <b-link
                v-b-modal.privacy-modal>{{ $t('privacy_policy') }}</b-link>
            </b-form-checkbox>
          </div>

          <b-alert variant="danger" v-if="errorMessage != ''" :show="true">{{ errorMessage }}</b-alert>
          <b-button v-else variant="primary" type="submit" :disabled="hotseat.players.length <= 1">{{ $t('create_team')
          }}
          </b-button>
        </b-form>

      </b-container>

      <!-- The event is individual or teams and not a 'hotseat' event -->
      <b-container v-else>

        <h2 class="mb-4">{{ $t('who_are_you') }}</h2>

        <b-form @submit.prevent="create_player(player)">

          <mediaupload label="" accept="image/*" icon="camera" v-model="player.avatarId" />

          <b-form-group :label="$t('your_name')">
            <b-form-input id="playername" name="playername" v-model="player.name" required />
          </b-form-group>

          <div class="form-group">
            <b-form-checkbox id="terms" name="terms" required>{{ $t('agree_with_terms_and_conditions') }} <b-link
                v-b-modal.terms-modal>{{ $t('terms_and_conditions') }}</b-link>
            </b-form-checkbox>
          </div>

          <div class="form-group">
            <b-form-checkbox id="privacy" name="privacy" required>{{ $t('agree_with_privacy_policy') }} <b-link
                v-b-modal.privacy-modal>{{ $t('privacy_policy') }}</b-link>
            </b-form-checkbox>
          </div>

          <b-button variant="primary" type="submit">{{ $t('create_player') }}</b-button>

        </b-form>

      </b-container>

      <!-- Terms and Conditions Modal -->
      <b-modal id="terms-modal" size="xl" :title="$t('terms_and_conditions')" ok-only :ok-title="$t('close')">
        <div v-if="event.Reseller" v-html="event.Reseller.terms_and_conditions" />
      </b-modal>

      <!-- Privacy Policy Modal -->
      <b-modal id="privacy-modal" size="xl" :title="$t('privacy_policy')" ok-only :ok-title="$t('close')">
        <div v-if="event.Reseller" v-html="event.Reseller.privacy_policy" />
      </b-modal>
    </template>

  </main>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'Whoareyou',
  data: function () {
    return {
      errorMessage: '',
      hotseat: {
        name: "",
        avatarId: null,
        players: [{}]
      }
    }
  },
  computed: {
    ...mapState('socket', ['event', 'game', 'code', 'players', 'chat', 'playerId']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['event_home_url', 'player', 'team', 'player_by_id', 'have_team', 'has_seen_intro']),

    next_page_url() {
      let url = this.event.teams && !this.have_team && this.event.team_type == 'default' ? '/team' : this.event_home_url

      if (this.game && this.game.game_type && !this.has_seen_intro) {
        switch (this.game.game_type) {
          case 'imposter':
            url = '/gameintro'
            break
          default:
            break
        }
      }

      return url
    }
  },
  watch: {
  },
  components: {
  },
  sockets: {
    playerId() {
      this.$router.push(this.next_page_url)
    },
    team_invalid() {
      // console.log(data)
    }
  },
  methods: {
    ...mapActions('socket', ['join', 'leave', 'create_player', 'change_player', 'create_hotseat_team']),

    checkDuplicateName() {
      const findDuplicates = list => list.filter((item, index) => list.indexOf(item) !== index)
      const duplicates = findDuplicates(this.hotseat.players.map(p => p.name))

      this.errorMessage = ''
      if (duplicates.length > 0) {
        this.errorMessage = this.$t('duplicate_names')
      }
    }
  },
}
</script>
<template>
  <div>
    <!-- <div style="z-index:9999; position: relative">
      <input type="number" step="0.01" v-model="offsetX">
      <input type="number" step="0.01" v-model="offsetY">
      <input type="number" step="0.01" v-model="offsetZ">
    </div> -->
    <video class="ar-view" :ref="`video`" autoplay playsinline></video>
    <babylon_view :glb="object" class="ar-view" @render="onRenderCanvas" @assetsLoaded="onAssetsLoaded"
      @sceneReady="onSceneReady" :cast-shadow="true" :attachCameraControl="false">
    </babylon_view>
  </div>
</template>

<script>
import ARToolkit from "@ar-js-org/artoolkit5-js";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { AssetContainer } from "@babylonjs/core/assetContainer";
import { Camera } from "@babylonjs/core/Cameras/camera";
import { Scene } from "@babylonjs/core/scene";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import "@babylonjs/core/Animations/animatable";
import { BoundingBox, SceneOptimizer } from "@babylonjs/core";

export default {
  data() {
    return {
      arController: null,
      markerRoot: null,
      markerId: null,
      stream: null,
      videoWidth: 1024,
      videoHeight: 1024,
      camera: null,
      cameraParamUrl: `${process.env.VUE_APP_API_URL}ar/camera_params-iphone.dat?d=${new Date().valueOf()}`,
      // cameraParamUrl: `${process.env.VUE_APP_API_URL}ar/camera_params.dat?d=${new Date().valueOf()}`,
      markerUrl: `${process.env.VUE_APP_API_URL}ar/marker.patt?d=${new Date().valueOf()}`,
      hideObjectInterval: null,
      markerMatrix: null,
      offsetX: 0,
      offsetY: 0,
      offsetZ: 0,
    };
  },
  props: {
    object: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.init());
  },
  beforeDestroy() {
    this.cleanup();
  },
  methods: {
    init() {
      navigator.mediaDevices.getUserMedia({
        video: {
          aspectRatio: 1,
          facingMode: "environment",
        },
        audio: false,
      }).then((stream) => {
        this.stream = stream;
        this.$refs.video.srcObject = stream;
      });
    },
    cleanup() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.stream = null;
      }
      if (this.arController) {
        this.arController.dispose();
        this.arController = null;
      }
    },
    onAssetsLoaded(scene, container) {
      const worldExtends = scene.getWorldExtends(function (mesh) {
        return mesh.isVisible && mesh.isEnabled();
      });
      // this.offsetX = worldExtends.max.x - worldExtends.min.x;
      this.offsetY = (worldExtends.max.y - worldExtends.min.y) / 2;
      // this.offsetZ = worldExtends.max.z - worldExtends.min.z;

      this.markerRoot = container.meshes[0];
      this.markerRoot.setEnabled(false);

      clearInterval(this.hideObjectInterval);
    },
    onSceneReady(scene, camera) {
      this.camera = camera;
      scene.useRightHandedSystem = true;

      // new DirectionalLight("DirectionalLight", new Vector3(0, -1, 0), scene);
      // new DirectionalLight("DirectionalLight", new Vector3(0, 1, 0), scene);
      // new DirectionalLight("DirectionalLight", new Vector3(0, 1, 0), scene);
      //create 3 directional lights to light the scene from different directions
      new DirectionalLight("DirectionalLight", new Vector3(1, 1, 0), scene);
      new DirectionalLight("DirectionalLight", new Vector3(-1, -1, 0), scene);
      new DirectionalLight("DirectionalLight", new Vector3(0.5, 0, 1), scene);
      ARToolkit.ARController.initWithDimensions(this.videoWidth, this.videoHeight, this.cameraParamUrl).then((controller) => {
        this.arController = controller;
        this.arController.loadMarker(this.markerUrl).then((id) => {
          this.markerId = id;
          this.arController?.trackPatternMarkerId(this.markerId, 1);
        });

        camera.freezeProjectionMatrix(Matrix.FromArray(this.arController.getCameraMatrix()));
        this.arController.addEventListener("getMarker", this.onMarkerFound);
      });

      // SceneOptimizer.OptimizeAsync(scene);
    },
    onMarkerFound(ev) {
      if (ev.data.type === ARToolkit.ARToolkit.PATTERN_MARKER) {
        if (this.markerId === null) return;
        if (ev.data.marker.idPatt === this.markerId) {
          this.markerMatrix = ev.data.matrixGL_RH;

          clearInterval(this.hideObjectInterval);
          this.hideObjectInterval = setInterval(() => {
            this.markerMatrix = null;
          }, 1000 / 30);
        }
      }
    },
    onRenderCanvas(scene) {
      if (!this.arController || !this.markerRoot) return;
      this.arController.process(this.$refs.video);

      this.positionObject();
    },
    positionObject() {
      if (this.markerMatrix !== null) {
        let babylonMatrix = Matrix.FromArray(this.markerMatrix);

        // Rotate the matrix 90 degrees around the y-axis (upward direction in Babylon.js)
        let rotationMatrix = Matrix.RotationX(Math.PI / 2);

        // Apply the rotation to the existing matrix
        babylonMatrix = rotationMatrix.multiply(babylonMatrix);


        babylonMatrix = babylonMatrix
          .multiply(Matrix.Translation(0, this.offsetY, 0));

        this.markerRoot.setPivotMatrix(babylonMatrix, false);
        //check if enabled, otherwise set enabled
        if (!this.markerRoot.isEnabled()) {
          this.markerRoot.setEnabled(true);
        }
      } else {
        //check is enabled and disable
        if (this.markerRoot.isEnabled()) {
          this.markerRoot.setEnabled(false);
        }
      }
    }
  },
};
</script>

<style scoped>
canvas {
  pointer-events: none;
}
</style>

<template>
  <div id="task-content" class="task-photo">
    
    <b-container>
      <b-form @submit.prevent="">
        <mediaupload label="" accept="image/*" icon="camera" v-model="mediaId" />

        <b-form-group :label="$t('comment')">
          <b-form-textarea v-model="player_remarks" />
        </b-form-group>
        
      </b-form>

      <!-- Error message in case something went wrong -->
      <b-alert variant="danger" :show="message != ''">{{message}}</b-alert>

      <!-- Submit answer button -->
      <b-button v-if="!loading" :disabled="btnSubmitEnabled" variant="primary" @click="submitAnswer">{{$t('send')}}</b-button>
      <loader v-else />
    </b-container>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import task from '../../mixins/task'

export default {
  name: 'PhotoTask',
  mixins: [ task ],
  data: function() {
    return {

    }
  },
  computed: {
    ...mapState('socket', ['code'])
  },  
}
</script>
<template>
  <div id="header">
      
    <b-container fluid>
      <h1>{{ title }}</h1>
    </b-container>

    <!-- Tabs -->
    <slot name="tabs"></slot>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'EventHeader',
  data: function() {
    return {
    }
  },
  props: {
    title: String
  },
  computed: {
    ...mapState('socket', ['event', 'code', 'players', 'chat']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['player', 'player_by_id']),
  },
  watch: {
  },
  async mounted() {
  },
  components: { },
  methods: {
  }
}
</script>
<template>

  <main class="team" v-if="event">
    <template v-if="event.teams">

      <!-- Zit al in een team -->
      <template v-if="have_team">
        <b-container>
          <div v-if="player && playerId">
            <h2>{{ $t('welcome') }} {{ player.name }}, </h2>
            <p>{{ $t('already_in_a_team') }}</p>
          </div>

          <b-button variant="secondary" @click="leave_team">{{ $t('leave_team') }}</b-button>
          <b-button variant="primary" :to="this.event_home_url">{{ $t('start') }}</b-button>
        </b-container>
      </template>

      <!-- Join een team -->
      <template v-else>
        <b-container>

          <!-- Team Joinen -->
          <b-form @submit.prevent="join_team(joinTeam)" v-if="teams" id="join-team">
            <h3 class="mb-4">{{ $t('join_a_team') }}</h3>
            <b-form-group>
              <b-form-select v-model="joinTeam" required>
                <option v-if="!teams || teams.length == 0" value=null disabled>{{ $t('no_team_available') }}
                </option>
                <option v-for="team in teams" :key="`select-team-${team.id}`" :value="team">{{ team.name }}</option>
              </b-form-select>
            </b-form-group>

            <b-button variant="primary" type="submit">{{ $t('join_team') }}</b-button>
          </b-form>

          <b-button variant="link" v-on:click="showCreateTeam = !showCreateTeam">{{ $t('create_new_team') }}</b-button>

          <!-- Team Maken -->
          <transition name="tabbar" mode="out-in">
            <b-form v-if="showCreateTeam" @submit.prevent="create_team(teamToCreate)" id="create-team">

              <h3 class="mb-4">{{ $t('create_new_team') }}</h3>
              <mediaupload label="" accept="image/*" icon="camera" v-model="teamToCreate.avatarId" />

              <b-form-group>
                <b-form-input v-model="teamToCreate.name" required />
              </b-form-group>

              <b-button variant="primary" type="submit">{{ $t('create_team') }}</b-button>
            </b-form>
          </transition>

        </b-container>
      </template>
    </template>

    <div v-else>Dit event wordt zonder teams gespeeld.</div>

  </main>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'Team',
  data: function () {
    return {
      joinTeam: null,
      showCreateTeam: false,
      teamToCreate: {
        avatarId: null,
        name: ""
      }
    }
  },
  computed: {
    ...mapState('socket', ['event', 'code', 'players', 'teams', 'chat', 'playerId']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['event_home_url', 'player', 'player_by_id', 'team', 'have_team']),
  },
  watch: {
  },
  components: {
  },
  sockets: {
    team_joined() {
      this.$router.push(this.event_home_url)
    },
    teamname_exist() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement

      // Create the message
      const vNodesMsg = h(
        'p',
        [
          h('p', this.$t('team_exists_content')),
        ]
      )
      // Create the title
      const vNodesTitle = h(
        'div',
        [
          h('strong', { class: 'mr-2' }, this.$t('team_exists_title')),
        ]
      )
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        variant: 'success'
      })
    }
  },
  methods: {
    ...mapActions('socket', ['leave', 'create_player', 'change_player', 'create_team', 'join_team', 'leave_team']),
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import socket from 'ub-lib/sockets/index'
import backbtn from './backbtn'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: 'en',
    numPendingRequests: 0,
    initialized: false,
    transitionName: "slide-left",
    task_status_filter: "todo",
    device_info: null,
    app_info: null,
    debuggingEnabled: false,
    firebase_token: ""
  },
  mutations: {
    locale(state, val) {
      state.locale = val
    },
    numPendingRequests(state, numPendingRequests) {
      state.numPendingRequests = numPendingRequests
    },
    initialized(state, initialized) {
      state.initialized = initialized
    },
    transition(state, transition) {
      state.transitionName = transition
    },
    task_status_filter(state, val) {
      state.task_status_filter = val
    },
    device_info(state, val) {
      state.device_info = val
    },
    app_info(state, val) {
      state.app_info = val
    },
    debuggingEnabled(state, enabled) {
      state.debuggingEnabled = enabled
    },
    firebase_token(state, token) {
      state.firebase_token = token
    }
  },
  getters: {
    isLoading: (state) => { return state.numPendingRequests > 0 },
    transition: (state) => { return state.transitionName }
  },
  actions: {
    //INITIALIZATION, called from navigation guard
    async init({ commit, dispatch }) {
      //get language code
      let lang = await Device.getLanguageCode()
      commit('locale', lang.value)

      let info = await Device.getInfo()
      commit('device_info', info)

      if (info.platform != 'web') {
        let appInfo = await App.getInfo()
        commit('app_info', appInfo)
      }

      //try to find previous event-code
      const playerId = await Preferences.get({ key: 'playerId' })
      if (playerId && playerId.value) {
        commit('socket/playerId', parseInt(playerId.value))
      }

      //try to find previous event-code
      const code = await Preferences.get({ key: 'code' })
      if (code && code.value) {
        commit('socket/code', code.value)
      }

      await dispatch('socket/init')

      commit('initialized', true)
    },
  },
  modules: {
    socket: socket(process.env.VUE_APP_API_URL),
    backbtn: backbtn
  }
})

export default {
  methods: {
    getOption(object, key) {
      let option = object.Option.find((p) => p.key == key)
      if (option) {
        return option.value
      } else {
        return `Option ${key} not found on ${object}`
      }
    }
  }
}
<template>
  <!-- TODO: Nog iets bedenken als er maar 1 ronde is en hoe we hiermee om willen gaan. Deze round-list is voor nu het meest overzichtelijk om te zien wat er gebeurd en hoe we navigeren -->
  <div class="roundlist">
    <eventheader :title="$t('rounds')" />

    <div id="scroll-container">
      <b-card v-if="num_available_rounds == 0">
        {{ $t('no_rounds_available_text') }}
      </b-card>

      <template v-for="(round) in game.Rounds">
        <b-card :key="`round-${round.id}`" v-if="round_available(round.id) && !round_hidden(round.id)">
          <router-link :to="`/event/rounds/${round.id}`" class="stretched-link" />

          <h4 class="card-title"><span class="task-count">{{ num_complete_tasks(round) }}/{{ num_available_tasks(round)
          }}</span> {{round.title}}</h4>

          <!-- Arrow icon -->
          <font-awesome-icon class="card-icon" :icon="['far', 'angle-right']" />
        </b-card>
      </template>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RoundList',
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('socket', ['event', 'game']),
    ...mapGetters('socket', ['round_available', 'round_hidden', 'is_task_complete', 'task_available']),
    ...mapGetters(['isLoading']),

    num_available_rounds() {
      return this.game.Rounds.reduce((prev, current) => this.round_available(current.id) && !this.round_hidden(current.id) ? prev + 1 : prev, 0)
    }
  },
  watch: {
  },
  async mounted() {
  },
  components: {},
  methods: {
    num_complete_tasks(round) {
      return round.Tasks.reduce((prev, current) => this.is_task_complete(current.id) ? prev + 1 : prev, 0)
    },
    num_available_tasks(round) {
      return round.Tasks.reduce((prev, current) => this.task_available(current.id) ? prev + 1 : prev, 0)
    }
  }
}
</script>
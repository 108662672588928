<template>
  <div id="app">
    <template v-if="initialized">
      <!-- <div id="mobile-landscape-message">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'mobile']" />
          <font-awesome-icon :icon="['far', 'mobile']" />
        </div>
        Landscape mode is not supported. Use UB Games in portrait mode.
      </div> -->

      <topbar />
      <transition :name="transition" mode="">
        <router-view id="main-view" />
      </transition>
    </template>
  </div>
</template>

<script>
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { initializeApp } from "firebase/app";
import { Capacitor } from "@capacitor/core";

import { mapState, mapGetters } from "vuex";
import { Preferences } from "@capacitor/preferences";

export default {
  name: "App",
  data: function () {
    return {
      skin: "",
    };
  },
  computed: {
    ...mapGetters(["transition"]),

    ...mapState(["initialized", "locale"]),
    ...mapState("socket", ["code", "playerId", "event", "game"]),
  },
  watch: {
    locale(newLocale) {
      this.$i18n.locale = newLocale;
    },

    skin(newSkin) {
      document.body.classList.remove(...document.body.classList);
      document.body.classList.add(newSkin);
    },

    event: {
      deep: false,
      handler(newEvent) {
        if (newEvent && newEvent.Reseller && newEvent.Reseller) {
          this.changeReseller(newEvent.Reseller);
        }
      },
    },
    game: {
      deep: true,
      handler() {
        this.setSkin();
      },
    },
    code(newValue) {
      if (newValue == null) {
        Preferences.remove({ key: "code" });
        this.$router.push("/");
      } else {
        Preferences.set({ key: "code", value: newValue.toString() });
      }
    },
    playerId(newValue) {
      if (newValue == null) {
        Preferences.remove({ key: "playerId" });
        Preferences.remove({ key: "reseller" });

        this.$router.push("/").then(() => window.location.reload());
      } else {
        Preferences.set({ key: "playerId", value: newValue.toString() });
      }
    },
  },
  async created() {
    await this.setSkin();

    if (Capacitor.isNativePlatform()) {
      initializeApp();
      const permissions = await FirebaseMessaging.requestPermissions();
      const token = await FirebaseMessaging.getToken();
      this.$store.commit("firebase_token", result.token);
    }

    // await PushNotifications.requestPermissions();
    // await PushNotifications.register();

    // Get FCM token instead the APN one returned by Capacitor
    // FCM.getToken()
    //   .then((r) => {
    //     this.$store.commit('firebase_token', r.token)
    //     console.log(`${r.token}`)
    //   })
    //   .catch((err) => console.log(err));
  },
  methods: {
    async setSkin() {
      this.skin = "ub-games";

      //check for game skin
      if (this.game && this.game.skin) {
        this.skin = this.game.skin;
      } else {
        //get reseller
        let option = await Preferences.get({ key: "reseller" });
        if (option && option.value) {
          let reseller = JSON.parse(option.value);
          if (reseller && reseller.skin && reseller.skin != "") {
            this.skin = reseller.skin;
          }
        }
      }
    },
    async changeReseller(reseller) {
      await Preferences.set({
        key: "reseller",
        value: JSON.stringify(reseller),
      });
      this.setSkin();
    },
  },
};
</script>

<template>
  <div class="media-upload">
    <b-form-group :label="label">
      <template v-if="media">
        <!-- Image Preview. For sizing options see https://www.npmjs.com/package/express-sharp#client-integration -->
        <mediaviewer :media="media" width="640" />

        <!-- Remove image button -->
        <b-button
          variant="primary"
          class="remove-media btn-icon"
          @click="removeImage"
        >
          <font-awesome-icon :icon="['far', 'times']" />
        </b-button>
      </template>

      <template v-else>
        <font-awesome-icon
          v-if="!loading"
          :icon="['far', icon]"
          id="upload-icon"
        />
        <b-spinner v-else />

        <!-- File Input: Android -->
        <template v-if="device_info && device_info.platform == 'android'">
          <b-form-file
            :disabled="loading"
            id="answer-logo"
            :accept="accept"
            :capture="true"
            v-model="file"
            placeholder=""
            drop-placeholder=""
          ></b-form-file>
          <b-form-file
            :disabled="loading"
            id="answer-logo-no-capture"
            :accept="accept"
            class="no-capture"
            v-model="file"
            :placeholder="$t('browse')"
            drop-placeholder=""
          ></b-form-file>
        </template>

        <!-- File Input: Else -->
        <b-form-file
          v-else
          :disabled="loading"
          id="answer-logo"
          :accept="accept"
          v-model="file"
          placeholder=""
          drop-placeholder=""
        ></b-form-file>
      </template>
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MediaUpload",
  data: function () {
    return {
      file: null,
      media: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(["device_info"]),
  },
  props: {
    accept: String,
    label: String,
    icon: String,
  },
  watch: {
    file() {
      this.updateItem();
    },
  },
  methods: {
    removeImage() {
      //TODO: Remove image from server aswell?
      this.file = null;
      this.media = null;

      this.$emit("input", null);
    },
    updateItem() {
      let form = new FormData();
      form.append("file", this.file);

      // TODO: Image eerst deleten ivm diskspace?
      this.loading = true;
      this.$store
        .dispatch(`socket/create_media`, form)
        .then((media) => {
          this.media = media;

          this.$emit("input", this.media.id);

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.remove-media {
  color: black;
}

.spinner-border {
  color: black;
}
</style>

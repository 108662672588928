<template>
  <main class="home">

    <mediaviewer v-if="logo" :media="logo" width="640" class="logo" />
    <div v-else class="logo">
      <b-img width="100" fluid :src="require('../images/ub-games-logo.png')" />
    </div>

    <b-container v-if="!loading">
      <b-form @submit.prevent="onSubmitEventCode">
        <b-form-group :label="$t('enter_code')">
          <b-form-input v-model="event_code" required :disabled="connecting" />
        </b-form-group>
        <b-button variant="primary" type="submit" :disabled="connecting">{{ $t('check_code') }}</b-button>
      </b-form>

      <b-alert variant="danger" :show="message != ''">{{ message }}</b-alert>
    </b-container>
    <loader v-else />

    <b-button variant="link" to="/about">{{ $t('about_ub_games') }}</b-button>
    <!-- TODO: Remove button when AR is tested -->
    <!-- <b-button variant="link" to="/ar" class="mt-0 pt-0">AR</b-button> -->
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Preferences } from '@capacitor/preferences';

export default {
  name: 'Home',
  data: function () {
    return {
      loading: false,
      message: "",
      logo: null
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'transition']),
    ...mapState('socket', ['event', 'playerId', 'connecting']),
    ...mapGetters('socket', ['player']),

    event_code: {
      get() { return this.$store.state.socket.code },
      set(value) { return this.$store.commit(`socket/code`, value) }
    },
  },
  async mounted() {
    let option = await Preferences.get({ key: 'reseller' })
    if (option && option.value) {
      let reseller = JSON.parse(option.value)
      if (reseller && reseller.Logo) {
        this.logo = reseller.Logo
      }
    }
  },
  components: {
  },
  methods: {
    ...mapActions('socket', ['join', 'leave', 'create_player']),
    onSubmitEventCode() {
      this.loading = true
      this.join()
    }
  },

  //listen to socket events
  sockets: {
    connect_error(err) {
      console.log(err)
      this.loading = false
      this.message = this.$t('connection_error')
    },

    //wrong code entered
    invalid_event() {
      this.loading = false
      this.message = this.$t('invalid_code')
    },
    //event received
    event(event) {
      this.loading = false

      // in principe kunnen we er vanuit gaan dat je hier altijd een geldig event ontvangen, die check zit op de server
      //voor de zekerheid check ik em hier alsnog en navigeren we naar de game introductie
      if (event) {
        this.$router.push(`/gamedescription`)
      }
    }
  }
}
</script>

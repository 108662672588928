<template>
  <div class="help">

    <eventheader :title="$t('help')" />

    <div id="scroll-container">
      <b-container>
        <div v-html="game.help" />
      </b-container>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Help',
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState('socket', ['event', 'code', 'players', 'chat', 'game']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['player', 'player_by_id']),
  },
  watch: {
  },
  async mounted() {
  },
  components: { },
  methods: {
  }
}
</script>

<template>
  <div class="round" v-if="round">
    <transition :name="transition" mode="">
      <router-view id="round-view" />
    </transition>
    
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Round',
  data: function() {
    return {}
  },
  computed: {
    ...mapState('socket', ['event', 'game']),
    ...mapGetters(['isLoading','transition']),

    round: {
      get() { return this.game.Rounds.find((r) => r.id == this.$route.params.roundId ) }
    },
  },
  watch: {
  },
  components: {},
  methods: {
  }
}
</script>

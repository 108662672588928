<template>
  <div class="chat">
    <eventheader :title="$t('chat')">
      <template v-slot:tabs v-if="event.teams">
        <div id="tabs">
          <div
            class="tab"
            @click="
              jump_to_bottom = true;
              activeTab = 'event';
            "
            v-bind:class="{ active: activeTab == 'event' }"
          >
            {{ $t("event") }}
            <font-awesome-icon
              v-if="
                unread_chat_messages && unread_teamchat_messages.includes(-1)
              "
              :style="{ color: 'red' }"
              transform="shrink-6 right-8 up-8"
              :icon="['fas', 'circle']"
            />
          </div>

          <div
            class="tab"
            @click="
              jump_to_bottom = true;
              activeTab = 'team';
            "
            v-bind:class="{ active: activeTab == 'team' }"
          >
            {{ $t("team") }}
            <font-awesome-icon
              v-if="unread_teamchat_messages.includes(team.id)"
              :style="{ color: 'red' }"
              transform="shrink-6 right-8 up-8"
              :icon="['fas', 'circle']"
            />
          </div>
        </div>
      </template>
    </eventheader>

    <div id="scroll-container" ref="scrollcontainer">
      <b-container>
        <!-- Event chat -->
        <div class="tab-content" v-if="activeTab == 'event'">
          <template v-for="message in event_chat">
            <chatitem :message="message" :key="`chatitem-${message.id}`" />
          </template>

          <div class="chat-input">
            <b-form-input
              v-model="chat_message"
              @keydown.enter="send_chat_message('event')"
            />
            <b-button variant="primary" @click="send_chat_message('event')">
              <font-awesome-icon
                class="card-icon"
                :icon="['far', 'paper-plane']"
              />
            </b-button>
          </div>
        </div>

        <!-- Team chat -->
        <div class="tab-content" v-if="activeTab == 'team'">
          <template v-for="message in team_chat">
            <chatitem :message="message" :key="`chatitem-${message.id}`" />
          </template>

          <div class="chat-input">
            <b-form-input
              v-model="chat_message"
              @keydown.enter="send_chat_message('team')"
            />
            <b-button variant="primary" @click="send_chat_message('team')">
              <font-awesome-icon
                class="card-icon"
                :icon="['far', 'paper-plane']"
              />
            </b-button>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>
<style></style>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "Chat",
  data: function () {
    return {
      chat_message: "",
      activeTab: "event",
      numEventMessages: 5,
      numTeamMessages: 5,
      jump_to_bottom: true,
    };
  },
  computed: {
    ...mapState("socket", [
      "event",
      "code",
      "players",
      "teams",
      "unread_chat_messages",
      "unread_teamchat_messages",
    ]),
    ...mapGetters(["isLoading"]),
    ...mapGetters("socket", [
      "player",
      "team",
      "player_by_id",
      "have_team",
      "event_chat",
      "team_chat",
    ]),
  },
  watch: {
    team_chat() {
      if (this.activeTab == "team") {
        this.clear_teamchat_id(this.team.id);
        // this.$store.commit('socket/unread_chat_messages', false)
      }
    },
    event_chat() {
      if (this.activeTab == "event") {
        this.clear_teamchat_id(-1);
        this.$store.commit("socket/unread_chat_messages", false);
      }
    },
    activeTab(newTab) {
      let id = -1;
      if (newTab == "team") id = this.team.id;
      this.clear_teamchat_id(id);
      this.$store.commit("socket/unread_chat_messages", false);
    },
  },
  async mounted() {
    if (this.unread_teamchat_messages.includes(this.team.id)) {
      this.activeTab = "team";
      this.clear_teamchat_id(this.team.id);
    }

    this.$store.commit("socket/unread_chat_messages", false);

    this.$nextTick(() => {
      setTimeout(() => {
        this.jump_to_bottom = true;

        const container = this.$refs.scrollcontainer;
        container.scrollTop = container.scrollHeight;
      }, 500);
    });
  },
  components: {},
  updated() {
    if (!this.jump_to_bottom) return;

    const container = this.$refs.scrollcontainer;
    container.scrollTop = container.scrollHeight;

    this.jump_to_bottom = false;
  },
  methods: {
    clear_teamchat_id(id) {
      if (this.unread_teamchat_messages.length > 0) {
        const ids = this.unread_teamchat_messages;
        const idx = ids.findIndex((i) => i == id);
        if (idx > -1) {
          ids.splice(idx, 1);
          this.$store.commit("socket/unread_teamchat_messages", ids);
        }
      }
    },
    send_chat_message(channel) {
      //dont allow sending to team when not in team event
      if (channel == "team" && (!this.event.teams || !this.have_team)) return;

      //create message data
      let msg = {
        message: this.chat_message,
        channel: channel,
      };

      //set teamId when sending to team
      if (channel == "team") {
        msg.teamId = this.team.id;
      }

      //dispatch event
      this.$store.dispatch("socket/send_chat_message", msg);

      //reset chatmessage
      this.chat_message = "";
    },
  },
  sockets: {
    // eslint-disable-next-line no-unused-vars
    // chat_message(message) {
    //   this.$store.commit('socket/unread_chat_messages', false)
    // }
  },
};
</script>

<template>
    <div id="task-info">

        <b-alert variant="danger" :show="message != ''">{{message}}</b-alert>

        <b-button v-if="!loading" :disabled="btnSubmitEnabled" variant="primary" @click="submitAnswer">{{$t('next')}}</b-button>
        <loader v-else />

      <!-- TODO: Voor een 'info' pagina zouden we een 'leeg' antwoord kunnen submitten (de knop hernoemen naar 'verder' ofzo?) -->
      <!-- De server kan dan deze taak ook op 'afgerond' zetten, waarbij we dan 'afgerond' kunnen misbruiken als 'gelezen' ofzo? -->
    </div>
</template>
<script>
import task from '../../mixins/task'

export default {
  name: 'InfoTask',
  mixins: [ task ]
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$socket.connected)?_c('loader'):(_vm.event && _vm.game && _vm.$socket.connected)?_c('main',{staticClass:"event"},[(_vm.event_uses_gps && !_vm.locationWatcher)?[_c('b-alert',{staticClass:"location-error-alert",attrs:{"show":_vm.locationErrorMessage != '',"variant":"danger"}},[_c('p',[_vm._v("Huidige toestemming: "+_vm._s(_vm.locationPermission))]),(_vm.locationPermission == 'denied')?_c('p',[_vm._v(_vm._s(_vm.$t("gps_denied_text")))]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.locationErrorMessage))]),_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.retryGps}},[_vm._v(_vm._s(_vm.$t("retry_gps"))+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'sync']}})],1)],1),_c('b-alert',{staticClass:"location-error-alert",attrs:{"show":!_vm.locationWatcher && _vm.locationErrorMessage == '',"variant":"danger"}},[(_vm.gettingLocation)?_c('b-spinner'):[_c('p',[_vm._v(_vm._s(_vm.$t("request_gps_text")))]),_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.requestGpsPermissions}},[_vm._v(_vm._s(_vm.$t("request_gps_btn"))+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'sync']}})],1)]],2)]:_vm._e(),(!_vm.player ||
    (_vm.event.teams && !_vm.have_team) ||
    _vm.$socket.client.auth.uid == undefined
    )?[_c('div',{attrs:{"id":"event-lobby"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("event_error")))]),_c('p',[_vm._v(_vm._s(_vm.$t("event_error_text")))]),_c('b-button',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("continue")))])],1)]:[(!_vm.event_started && !_vm.event_ended)?[_c('div',{attrs:{"id":"event-lobby"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("event_not_started")))]),(_vm.event.start_type == 'manager')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("event_not_started_text"))+" ")]):(_vm.event.start_type == 'time')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("event_starts_at"))+" "+_vm._s(_vm._f("formatChatDate")(new Date(_vm.event.start_date)))+" om "+_vm._s(_vm._f("formatChatTime")(new Date(_vm.event.start_date)))+" ")]):_vm._e()])]:(_vm.event_ended)?[_c('div',{attrs:{"id":"event-lobby"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("event_ended")))]),_c('p',[_vm._v(_vm._s(_vm.$t("event_ended_text")))]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/gameoutro"}},[_vm._v(_vm._s(_vm.$t("next")))])],1)]:(_vm.event_started && !_vm.event_ended)?[_c('transition',{attrs:{"name":"event-duration","mode":"out-in"}},[(_vm.event_started &&
          _vm.event.end_type == 'duration' &&
          !this.$route.params.taskId
          )?_c('div',{attrs:{"id":"event-duration"}},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"progress",style:({ width: _vm.timerProgress + '%' })})]),_vm._v(" "+_vm._s(_vm.timeRemaining)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":_vm.transition,"mode":""}},[_c('router-view',{attrs:{"id":"event-view"}})],1),_c('transition',{attrs:{"name":"tabbar","mode":"out-in"}},[(!this.$route.params.taskId ||
          (this.game && this.game.nav_type != 'none')
          )?_c('div',{attrs:{"id":"tabbar"}},[_c('router-link',{attrs:{"to":_vm.event_home_url}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'tasks']}})],1),_c('router-link',{attrs:{"to":"/event/highscore"}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trophy']}})],1),_c('router-link',{attrs:{"to":"/event/help"}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'info-circle']}})],1),(_vm.event.map_hidden != true && _vm.locationWatcher)?_c('router-link',{attrs:{"to":"/event/map"}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'map']}})],1):_vm._e(),(_vm.event.chat_hidden != true)?_c('router-link',{attrs:{"to":"/event/chat"}},[_c('font-awesome-layers',{staticClass:"fa"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'comments']}}),(_vm.unread_chat_messages)?_c('font-awesome-icon',{style:({ color: 'red' }),attrs:{"transform":"shrink-6 right-8 up-8","icon":['fas', 'circle']}}):_vm._e()],1)],1):_vm._e()],1):_vm._e()])]:_vm._e()]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { mapActions, mapGetters, mapState } from "vuex"
import option from 'ub-lib/mixins/option'

export default {
  data: function () {
    return {
      loading: false,
      answer: "",
      player_remarks: "",
      mediaId: null,
      message: "",
    }
  },
  mixins: [option],
  props: {
    round: null,
    task: null
  },
  watch: {
    answer(newval) {
      this.$store.commit('backbtn/useConfirm', !(newval == ""))
      this.$store.commit('backbtn/confirmMessage', this.$t("answer_not_submitted_message"));
    },
    mediaId(newval) {
      this.$store.commit('backbtn/useConfirm', !(newval == null))
      this.$store.commit('backbtn/confirmMessage', this.$t("answer_not_submitted_message"));
    }
  },
  computed: {
    ...mapState('socket', ['event']),
    ...mapGetters('socket', ['is_task_complete', 'is_task_pending', 'is_task_denied', 'answers_by_task_id', 'event_home_url']),

    btnSubmitEnabled() {
      return !((this.answer != "" || this.mediaId != null) || this.is_info_task)
    },

    is_info_task() {
      return this.getOption(this.task, 'type') == 'info'
    }
  },
  methods: {
    ...mapActions('socket', ['submit_answer']),
    submitAnswer() {
      this.loading = true
      this.message = ""

      this.submit_answer({ taskId: this.task.id, answer: this.answer, mediaId: this.mediaId, player_remarks: this.player_remarks })

      this.$store.commit('backbtn/useConfirm', false)
    },

    getIcon() {
      let type = this.getOption(this.task, 'type')
      let icon = ''
      switch (type) {
        case 'photo':
          icon = 'camera'
          break
        case 'video':
          icon = 'video'
          break
        default:
          break
      }
      return icon
    },

  },
  unmounted() {
    this.$store.commit('backbtn/useConfirm', false)
  },
  sockets: {
    submit_answer_error(message) {
      //TODO: Also send taskId from server so we can validate the taskId with the local Id
      this.loading = false
      this.message = message
    },

    answer_updated(answer) {
      //we only want to react on messages for this task.
      //TODO: Maybe create dynamic socket listeners for this specific task?
      if (answer.taskId == this.task.id) {
        this.loading = false
        this.message = this.$t(answer.status)

        if (this.is_info_task) {
          this.$nextTick(() => {
            this.$router.push(this.event_home_url)
          })
        }
      }
    },
  },
}
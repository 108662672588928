<template>
  <b-card :title="task.title" :key="`task-${task.id}`">        
    <!-- Points badge -->
    <b-badge variant="primary">{{ getOption(task,'points') }} {{ $t("points") }}</b-badge>

    <!-- Task icon -->
    <font-awesome-icon class="task-type" v-if="getIcon()" :icon="['far', getIcon()]"/>

    <!-- Arrow icon -->
    <font-awesome-icon class="card-icon" :icon="['far', 'angle-right']"/>

    <router-link :to="`/event/rounds/${round.id}/tasks/${task.id}`" class="stretched-link" />

  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import task from '../../mixins/task'
import option from 'ub-lib/mixins/option'

export default {
  name: 'TaskListItem',
  mixins: [ task, option ],
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState('socket', ['event']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['answers_by_task_id', 'is_task_complete', 'is_task_pending', 'is_task_denied', 'answers_by_task_id', 'task_num_tries', 'task_max_tries']),
  }
}
</script>
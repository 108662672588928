<template>
  <div class="map">
    <eventheader :title="$t('map')" />
    <l-map
      v-if="this.location && this.location.coords"
      :style="mapStyle"
      :zoom="zoom"
      :center="center"
      @update:zoom="(z) => (zoom = z)"
      @update:center="(z) => (customCenter = z)"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker :lat-lng="playerPosition">
        <l-icon
          :iconUrl="markerIconUrl(player.id)"
          :iconSize="[40, 40]"
          :iconAnchor="[20, 20]"
        />
        <l-popup :content="markerPopupContent(player.id)"></l-popup>
      </l-marker>

      <!-- TODO: DISABLED FOR OPTIMIZATION TESTING -->
      <!-- <template v-for="p in playerLatLngs">
        <l-marker v-if="p.playerId != player.id" :key="p.playerId" :lat-lng="[p.latitude, p.longitude]">
          <l-icon :iconUrl="markerIconUrl(p.playerId)" :iconSize="[40, 40]" :iconAnchor="[20, 20]" />
          <l-popup :content="markerPopupContent(p.playerId)"></l-popup>
        </l-marker>
      </template> -->

      <!-- TASKS -->
      <template v-for="task in gps_tasks">
        <template v-if="gpsTaskVisible(task)">
          <l-marker
            :key="`gps-task-marker-${task.id}`"
            :lat-lng="getOption(task, 'latlng').latlng"
          >
            <l-icon
              :iconUrl="iconUrl(task, 'task')"
              :iconSize="[40, 40]"
              :iconAnchor="[20, 40]"
            />
          </l-marker>
          <l-circle
            :key="`gps-task-radius-${task.id}`"
            :lat-lng="getOption(task, 'latlng').latlng"
            :radius="getOption(task, 'latlng').radius"
            :color="'blue'"
            :weight="0.1"
          />
        </template>
      </template>

      <!-- ROUNDS -->
      <template v-for="round in gps_rounds">
        <template v-if="gpsRoundVisible(round)">
          <l-marker
            :key="`gps-round-marker-${round.id}`"
            :lat-lng="getOption(round, 'latlng').latlng"
          >
            <l-icon
              :iconUrl="iconUrl(round, 'round')"
              :iconSize="[40, 40]"
              :iconAnchor="[20, 40]"
            />
          </l-marker>
          <l-circle
            :key="`gps-round-radius-${round.id}`"
            :lat-lng="getOption(round, 'latlng').latlng"
            :radius="getOption(round, 'latlng').radius"
            :color="'blue'"
            :weight="0.1"
          />
        </template>
      </template>

      <l-control position="bottomleft">
        <font-awesome-icon
          class="fa-lg"
          style="color: black"
          @click="reCenter"
          :icon="['far', 'crosshairs']"
        />
      </l-control>
    </l-map>

    <div v-else class="container">Waiting for GPS...</div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import option from "ub-lib/mixins/option";

export default {
  name: "Map",
  mixins: [option],
  data: function () {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 15,
      customCenter: null,
    };
  },
  computed: {
    ...mapState("socket", ["event", "location", "game"]),
    ...mapGetters("socket", [
      "player",
      "gps_rounds",
      "gps_tasks",
      "task_available",
      "round_available",
      "playerLatLngs",
      "player_has_team",
      "team_by_player_id",
      "player_by_id",
    ]),
    ...mapGetters(["isLoading"]),

    playerPosition() {
      var position = [51.6006604, 5.6124116];
      if (this.location && this.location.coords) {
        console.log(position, this.location.coords);
        position = [
          this.location.coords.latitude,
          this.location.coords.longitude,
        ];
      }

      return position;
    },

    center() {
      return (
        this.customCenter ?? [
          this.location.coords.latitude,
          this.location.coords.longitude,
        ]
      );
    },

    mapStyle() {
      return {
        height: `${window.innerHeight - 142}px`,
        width: `${window.innerWidth}px`,
      };
    },
  },
  watch: {
    location(loc) {
      if (loc && loc.coords && !this.center) {
        this.center = [loc.coords.latitude, loc.coords.longitude];
      }
    },
  },
  async mounted() {
    // console.log(this.location.coords)
    // if (this.location && this.location.coords) {
    //   this.center = [this.location.coords.latitude, this.location.coords.longitude]
    //   console.log(this.center)
    // }
  },
  components: {},
  methods: {
    ...mapActions("socket", [
      "leave",
      "change_player",
      "save_player",
      "leave_team",
    ]),

    iconUrl(item, type) {
      var url = require(`@/images/markers/${type}-locked.png`);
      let available =
        type == "task"
          ? this.task_available(item.id)
          : this.round_available(item.id);
      if (available) {
        url = require(`@/images/markers/${type}-unlocked.png`);
      }

      return url;
    },

    gpsRoundVisible(round) {
      const option = this.getOption(round, "hide_on_map");
      return option !== true || this.round_available(round.id);
    },
    gpsTaskVisible(task) {
      const option = this.getOption(task, "hide_on_map");
      return option !== true || this.task_available(task.id);
    },

    markerIconUrl(playerId) {
      let markerUrl = "/api/media/";
      if (process.env.VUE_APP_API_URL) {
        markerUrl = process.env.VUE_APP_API_URL + "api/media/";
      }

      if (
        this.event &&
        this.event.teams &&
        this.event.team_type == "hotseat" &&
        this.player_has_team(playerId)
      ) {
        const t = this.team_by_player_id(playerId);
        if (t && t.Avatar) {
          markerUrl =
            markerUrl +
            t.Avatar.path.replace("uploads", "") +
            `?w=40&h=40&f=webp&q=80&p&crop=true`;
        } else {
          markerUrl = require(`@/images/markers/team.png`);
        }
      } else {
        const p = this.player_by_id(playerId);
        if (p && p.Avatar) {
          markerUrl =
            markerUrl +
            p.Avatar.path.replace("uploads", "") +
            `?w=40&h=40&f=webp&q=80&p&crop=true`;
        } else {
          markerUrl = require(`@/images/markers/player.png`);
        }
      }

      return markerUrl;
    },
    popupImageUrl(playerId) {
      let markerUrl = "/api/media/";
      if (process.env.VUE_APP_API_URL) {
        markerUrl = process.env.VUE_APP_API_URL + "api/media/";
      }

      if (
        this.event &&
        this.event.teams &&
        this.event.team_type == "hotseat" &&
        this.player_has_team(playerId)
      ) {
        const t = this.team_by_player_id(playerId);
        if (t && t.Avatar) {
          markerUrl =
            markerUrl +
            t.Avatar.path.replace("uploads", "") +
            `?w=380&f=webp&q=80&p&crop=true`;
        } else {
          markerUrl = require(`@/images/markers/team.png`);
        }
      } else {
        const p = this.player_by_id(playerId);
        if (p && p.Avatar) {
          markerUrl =
            markerUrl +
            p.Avatar.path.replace("uploads", "") +
            `?w=380&f=webp&q=80&p&crop=true`;
        } else {
          markerUrl = require(`@/images/markers/player.png`);
        }
      }

      return `${markerUrl}`;
    },
    markerPopupContent(playerId) {
      let content = "";

      if (
        this.event &&
        this.event.teams &&
        this.event.team_type == "hotseat" &&
        this.player_has_team(playerId)
      ) {
        const t = this.team_by_player_id(playerId);
        if (t) {
          content = `
            <img width="100%" src=${this.popupImageUrl(playerId)} />
            <h5 style="width:400px">${t.name}</h5>
          `;
        }
      } else {
        const p = this.player_by_id(playerId);
        if (p) {
          content = `
            <img src=${this.popupImageUrl(playerId)} />
            <h5 style="width:400px">${p.name}</h5>
          `;
        }
      }
      return content;
    },
    reCenter() {
      this.customCenter = null;
      // if (this.location && this.location.coords) {
      //   this.center = [this.location.coords.latitude, this.location.coords.longitude]
      // }
    },
  },
};
</script>

<style>
.leaflet-marker-icon {
  border-radius: 50%;
}

.leaflet-marker-pane > * {
  -webkit-transition: transform 1s linear;
  -moz-transition: transform 1s linear;
  -o-transition: transform 1s linear;
  -ms-transition: transform 1s linear;
  transition: transform 1s linear;
}
</style>

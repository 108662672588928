<template>
  <div id="topbar" v-if="$socket.connected">

    <div v-if="$route.path != '/'" @click="onPressBack" class="topbar-btn">
      <font-awesome-icon :icon="['far', 'arrow-left']" />
    </div>
    <router-link to="/settings" class="topbar-btn" v-if="event">
      <font-awesome-icon :icon="['far', 'cog']" />
    </router-link>

  </div>

</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Topbar',
  data: function () {
    return {

    }
  },
  computed: {
    ...mapGetters(['isLoading', 'transition']),
    ...mapState('socket', ['event', 'playerId']),

    ...mapGetters('socket', ['player', 'player_by_id']),
  },
  watch: {
  },
  async mounted() {
  },
  components: {
  },
  methods: {
    ...mapActions('backbtn', ['onPressBack'])
  }
}
</script>


<template>
  <div id="task-ar">
    <div class="ar-container">
      <arcamera v-if="ar" :object="getOption(task, 'ar_object')"></arcamera>
      <babylon_view v-else :glb="getOption(task, 'ar_object')" class="ar-view" @assetsLoaded="onAssetsLoaded"
        @sceneReady="onSceneReady" clearColor="#00000000" @render="onRenderCanvas" @progress="onProgress">
      </babylon_view>
    </div>
    <!-- <div v-if="!ar && progress < 1">{{ Math.round(progress * 100) }}%</div> -->
    <b-button class="btn-toggle-ar" @click="ar = !ar">Toggle AR</b-button>
  </div>
</template>

<script>
import ARToolkit from "@ar-js-org/artoolkit5-js";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { AssetContainer } from "@babylonjs/core/assetContainer";
import { Camera } from "@babylonjs/core/Cameras/camera";
import { Scene } from "@babylonjs/core/scene";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math";
import { Color4, Color3 } from "@babylonjs/core/Maths/math.color";
import { FramingBehavior } from "@babylonjs/core/Behaviors/Cameras/framingBehavior";

import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import "@babylonjs/core/Animations/animatable";
import task from '../../mixins/task'

export default {
  name: 'ArTask',
  mixins: [task],
  data() {
    return {
      ar: true,
      camera: null,
      markerRoot: null,
      progress: 0
    }
  },
  mounted() {
    this.progress = 0;
  },
  methods: {
    onAssetsLoaded(scene, container) {
      // container.materials.forEach((material) => {
      //   material.unlit = true;
      // });

      const framingBehavior = this.camera.getBehaviorByName("Framing");
      const worldExtends = scene.getWorldExtends(function (mesh) {
        return mesh.isVisible && mesh.isEnabled();
      });
      framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
    },
    onProgress(progress) {
      this.progress = progress.loaded / progress.total;
    },
    onSceneReady(scene, camera) {
      new DirectionalLight("DirectionalLight", new Vector3(0, -1, 0), scene);
      new DirectionalLight("DirectionalLight", new Vector3(0, 1, 0), scene);

      this.camera = camera;

      //disable mousewheel
      camera.lowerRadiusLimit = camera.upperRadiusLimit = camera.radius;
      camera.lowerRadiusLimit -= 8;
      camera.useNaturalPinchZoom = true;
      camera.panningSensibility = 0;
      camera.panningDistanceLimit = 0.25;
      camera.panningInertia = 0.05;

      // Enable camera's behaviors
      this.camera.useFramingBehavior = true;

      const framingBehavior = this.camera.getBehaviorByName("Framing");
      framingBehavior.framingTime = 0;
      framingBehavior.elevationReturnTime = -1;
    },
    onRenderCanvas(scene) {
    },
  }
}

</script>

<style>
.ar-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
}

.ar-view {
  width: 100%;
  aspect-ratio: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-toggle-ar {
  position: absolute;
  bottom: 1rem;
  width: 90vw;
  z-index: 100;
  left: 5vw;
}

audio {
  position: fixed;
  bottom: 5rem;
  left: 5vw;
  width: 90vw;
}
</style>
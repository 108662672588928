<template>
  <div class="tasklist">

    <eventheader :title="round.title">

      <template v-slot:tabs>
        <div id="tabs">
          <div class="tab" @click="activeTab = 'todo'" v-bind:class="{ active : activeTab == 'todo' }">
            {{$t('to_do')}}
            <span v-if="num_open_tasks > 0">{{ num_open_tasks }}</span>
          </div>

          <div class="tab" @click="activeTab = 'pending'" v-bind:class="{ active : activeTab == 'pending' }">
            {{$t('pending')}}
            <span v-if="num_pending_tasks > 0">{{ num_pending_tasks }}</span>
          </div>

          <div class="tab" @click="activeTab = 'complete'" v-bind:class="{ active : activeTab == 'complete' }">
            {{$t('completed')}}
            <span v-if="num_complete_tasks > 0">{{ num_complete_tasks }}</span>
          </div>
        </div>
      </template>
    </eventheader>

    <div id="scroll-container">
      <!-- Todo tasks -->
      <div class="tab-content" v-if="activeTab == 'todo'">
        <!-- No results -->
        <div v-if="num_open_tasks == 0" class="no-results">{{$t('no_to_do_tasks_available')}}</div>

        <!-- Show open tasks -->
        <template v-for="(task) in round.Tasks">
          <tasklistitem :task="task" :round="round" :key="`task-${task.id}`"
            v-if="task_available(task.id) && !task_hidden(task.id) && !is_task_complete(task.id) && !is_task_pending(task.id)" />
        </template>
      </div>

      <!-- Pending tasks -->
      <div class="tab-content" v-if="activeTab == 'pending'">

        <!-- No results -->
        <div v-if="num_pending_tasks == 0" class="no-results">{{$t('no_pending_tasks_available')}}</div>

        <!-- Show pendings tasks -->
        <template v-for="(task) in round.Tasks">
          <tasklistitem :task="task" :round="round" :key="`task-${task.id}`"
            v-if="task_available(task.id) && !task_hidden(task.id) && is_task_pending(task.id)" />
        </template>
      </div>

      <!-- Completed tasks -->
      <div class="tab-content" v-if="activeTab == 'complete'">

        <!-- No results -->
        <div v-if="num_complete_tasks == 0" class="no-results">{{$t('no_completed_tasks_available')}}</div>

        <!-- Show completed tasks -->
        <template v-for="(task) in round.Tasks">
          <tasklistitem :task="task" :round="round" :key="`task-${task.id}`"
            v-if="task_available(task.id) && !task_hidden(task.id) && is_task_complete(task.id)" />
        </template>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import option from 'ub-lib/mixins/option'

export default {
  name: 'TaskList',
  mixins: [option],
  data: function () {
    return {}
  },
  computed: {
    ...mapState(['task_status_filter']),
    ...mapState('socket', ['event', 'game']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['is_task_complete', 'is_task_pending', 'is_task_denied', 'answers_by_task_id', 'task_available', 'task_hidden']),


    activeTab: {
      get() {
        return this.task_status_filter
      },
      set(val) {
        this.$store.commit('task_status_filter', val)
      }
    },

    round: {
      get() { return this.game.Rounds.find((r) => r.id == this.$route.params.roundId) }
    },
    num_open_tasks() {
      return this.round.Tasks.reduce((prev, current) => this.task_available(current.id) && !this.is_task_complete(current.id) && !this.is_task_pending(current.id) ? prev + 1 : prev, 0)
    },
    num_pending_tasks() {
      return this.round.Tasks.reduce((prev, current) => this.is_task_pending(current.id) ? prev + 1 : prev, 0)
    },
    num_complete_tasks() {
      return this.round.Tasks.reduce((prev, current) => this.is_task_complete(current.id) ? prev + 1 : prev, 0)
    },
  },

  methods: {
  },
  watch: {
  },
  components: {},
}
</script>
<template>
  <canvas ref="canvas" touch-action="none"></canvas>
</template>

<script>
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Animations/animatable";
import "@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent";
import "@babylonjs/core/Helpers/sceneHelpers";

import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { FreeCamera } from "@babylonjs/core/Cameras/freeCamera";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";

import { Engine } from "@babylonjs/core/Engines/engine";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Scene } from "@babylonjs/core/scene";
import { Color4, Color3 } from "@babylonjs/core/Maths/math.color";
import { AssetContainer } from "@babylonjs/core/assetContainer";
import { Camera } from "@babylonjs/core/Cameras/camera";

export default {
  props: {
    glb: String,
    debugCube: {
      type: Boolean,
      default: false,
    },
    clearColor: {
      type: String,
      default: "#00000000",
    },
    fov: {
      type: Number,
      default: 0.3,
    },
    minZ: {
      type: Number,
      default: 0.1,
    },
    attachCameraControl: {
      type: Boolean,
      default: true,
    },
    cameraType: {
      type: String,
      default: "arc",
    },
    cameraTarget: {
      type: Object,
      default: () => new Vector3(0, 0, 0),
    },
    cameraPosition: {
      type: Object,
      default: () => new Vector3(0, 0, -3),
    },
  },
  data() {
    return {
      canvas: null,
      engine: null,
      camera: null,
      scene: null,
    };
  },
  methods: {
    emit(event, ...args) {
      this.$emit(event, ...args);
    },
    setupCanvas() {
      if (this.engine != null) {
        // Create our first scene.
        this.scene = new Scene(this.engine);
        this.scene.clearColor = Color4.FromHexString(this.clearColor);

        // This creates and positions a free camera (non-mesh)
        if (this.cameraType === "free") {
          this.camera = new FreeCamera("camera1", this.cameraPosition, this.scene);
          this.camera.setTarget(this.cameraTarget);
        } else {
          this.camera = new ArcRotateCamera(
            "camera1",
            Math.PI / 2, Math.PI / 4, 10,
            this.cameraTarget,
            this.scene,
          );
          // Positions the camera overwriting alpha, beta, radius

        }
        if (this.attachCameraControl) {
          this.camera.attachControl(this.canvas, false);
        } else {
          this.camera.setPosition(new Vector3(0, 0, 0));
        }
        this.camera.fov = this.fov;
        this.camera.minZ = this.minZ;

        SceneLoader.LoadAssetContainer(`${process.env.VUE_APP_API_URL}ar/`, `${this.debugCube ? 'object_1' : this.glb}.glb?d=${new Date().valueOf()}`, this.scene, (container) => {
          // add all
          container.addAllToScene();

          // emit event for assets loaded
          if (this.scene != null) {
            this.emit("assetsLoaded", this.scene, container);
          }
        },
          (progress) => {
            this.emit("progress", progress);
          });

        // resize engine when canvas is resized
        window.addEventListener("resize", () => this.engine?.resize());
        this.emit("sceneReady", this.scene, this.camera, this.engine);

        // Render every frame
        this.engine.runRenderLoop(() => {
          if (this.scene != null) {
            this.emit("render", this.scene);
            this.scene.render();
          }
        });
      }
    },
    dispose() {
      // emit dispose
      this.emit("dispose");

      // dispose
      if (this.camera) this.camera.dispose();
      if (this.scene) this.scene.dispose();
      if (this.engine) this.engine.dispose();

      // set to null
      this.engine = null;
      this.scene = null;
      this.camera = null;
    },
  },
  mounted() {
    if (this.$refs.canvas) {
      this.canvas = this.$refs.canvas;
      this.engine = new Engine(this.canvas, true);
      this.setupCanvas();
    }
  },
  beforeDestroy() {
    this.dispose();
  },
};
</script>

<style scoped>
canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
</style>
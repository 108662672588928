<template>
  <b-table :items="highscores" :fields="highscore_fields" id="highscores-table">
    <template #cell(rank)="data">
      {{ data.index + 1 }}
    </template>

    <template #cell(name)="data">
      {{ data.item.name }} 
      <b-badge v-if="game.game_type == 'imposter' && event_ended && data.index == 0" variant="primary">Beste team</b-badge>
      <b-badge v-if="game.game_type == 'imposter' && event_ended && data.index == highscores.length -1" variant="primary">Beste rat</b-badge>
    </template>

    <template #cell(badges)="data">
      <div class="highscore-badges">
        <template v-if="event.teams && team_by_id(data.item.id)">
          <template v-for="playerId in team_by_id(data.item.id).TeamPlayers.map(tp => tp.playerId)">
            <template v-for="badge in player_badges(playerId)">
              <mediaviewer class="highscore-badge" v-if="badge.Image" :media="badge.Image" :key="'badge' + badge.id" width="25" />
            </template>
          </template>
        </template>

        <template v-else>
          <template v-for="badge in player_badges(data.item.id)">
            <mediaviewer class="highscore-badge" v-if="badge.Image" :media="badge.Image" :key="'badge' + badge.id" width="25" />
          </template>
        </template>
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HighscoreTable',
  data: function() {
    return {
      highscore_fields: [
          { key:"rank", label: "" },
          { key:"name", label: "" },
          { key:"score", label: "" },
          { key:"badges", label: "" },
      ],
    }
  },
  computed: {
    ...mapState('socket', ['event', 'code', 'game', 'players', 'chat', 'highscores']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['player', 'player_by_id', 'team_by_id', 'player_badges', 'event_ended']),
  },
  watch: {
  },
  async mounted() {
  },
  components: { },
  methods: {
  }
}
</script>

<template>
  <main class="about">
    
    <b-container>
      <!-- TODO Deze content moet nog dynamisch ingeladen worden vanuit ergens -->
      <b-img :src="require('../images/about-header.png')" fluid />
      <h1>{{$t('about_ub_games')}}</h1>
      <p>
        This app is built to give you the best gaming experience.
        We want to create stronger teams and better bonds
        It's scientifically proven that we will live longer than average when we're having fun! So enjoy the game you are going to play!
      </p>
    </b-container>

    <b-button variant="link" @click="$router.go(-1)">{{$t('back')}}</b-button>

  </main>
</template>

<script>
export default {
  name: 'About',
  data: function() {
    return {
      
    }
  },
  components: {
  },
  methods: {
  }
}
</script>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vue-filters'

// BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// CSS
import './app.scss'
import 'ub-lib/skins'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

//FONT AWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
library.add(far)
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

//i18n language
import i18n from './plugins/i18n';

//socket.io
import { io } from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended';

// Leaflet
import { LMap, LTileLayer, LMarker, LIcon, LCircle, LControl, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-circle', LCircle);
Vue.component('l-control', LControl);
Vue.component('l-popup', LPopup)

import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

//custom components
import Topbar from './components/Topbar.vue'
import TaskListItem from './components/tasks/TaskListItem'
import InfoTask from './components/tasks/Info'
import OpenTask from './components/tasks/Open'
import McTask from './components/tasks/MultipleChoice'
import PhotoTask from './components/tasks/Photo'
import VideoTask from './components/tasks/Video'
import ArTask from './components/tasks/Ar'
import EventHeader from './components/EventHeader'
import MediaUpload from './components/MediaUpload'
import HighscoreTable from './components/HighscoreTable'
import Loader from './components/Loader'
import ArCamera from './components/ArCamera'
import BabylonView from './components/BabylonView'

Vue.component('topbar', Topbar)
Vue.component('tasklistitem', TaskListItem)
Vue.component('infotask', InfoTask)
Vue.component('opentask', OpenTask)
Vue.component('fixed_opentask', OpenTask)
Vue.component('mctask', McTask)
Vue.component('phototask', PhotoTask)
Vue.component('videotask', VideoTask)
Vue.component('artask', ArTask)
Vue.component('eventheader', EventHeader)
Vue.component('mediaupload', MediaUpload)
Vue.component('loader', Loader)
Vue.component('highscore-table', HighscoreTable)
Vue.component('arcamera', ArCamera)
Vue.component('babylon_view', BabylonView)

import MediaViewer from 'ub-lib/components/MediaViewer'
Vue.component('mediaviewer', MediaViewer)

import ChatItem from 'ub-lib/components/ChatItem.vue'
Vue.component('chatitem', ChatItem)

const socket = io(process.env.VUE_APP_API_URL, {
  reconnectionAttempts: 5,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 15000,
  autoConnect: false
})

if (process.env.NODE_ENV == 'development') {
  socket.onAny((event, ...args) => {
    console.log(event, args);
  });
}
socket.on("connect_error", (err) => {
  console.log(err)
})

Vue.use(VueSocketIOExt, socket, { store })

//VUE
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  sockets: {
  },
  render: h => h(App)
}).$mount('#app')
<template>
  <div id="task-content" class="task-multiplechoice">

    <b-container>

     <b-form @submit.prevent="">
        <!-- Radio inputs -->
        <b-form-group>
          <b-form-radio-group
            v-model="answer"
            :options="getOption(task,'mc_choices')"
            stacked
            buttons
          ></b-form-radio-group>
        </b-form-group>
     </b-form>

      <!-- Error message in case something went wrong -->
      <b-alert variant="danger" :show="message != ''">{{message}}</b-alert>

      <!-- Submit answer button -->
      <b-button v-if="!loading" :disabled="btnSubmitEnabled" variant="primary" @click="submitAnswer">{{$t('send')}}</b-button>
      <loader v-else />

    </b-container>
    
 </div>

</template>
<script>
import task from '../../mixins/task'

export default {
  name: 'McTask',
  mixins: [ task ]
}
</script>
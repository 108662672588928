<template>
  <div :class="`task ` + getOption(task, 'type')" v-if="task">
    <!-- Task-component tonen, vraag moet/mag nog beantwoord worden -->
    <!-- TODO: Bij task denied mag je het nog een keer proberen -->
    <template v-if="!is_task_complete(task.id) && !is_task_pending(task.id)">
      <!-- Task 'open' -->
      <div id="header" class="task">
        <b-container>
          <!-- Points badge -->
          <b-badge variant="primary" class="ml-auto"
            >{{ getOption(task, "points") }} {{ $t("points") }}</b-badge
          >

          <h5>{{ task.title }}</h5>

          <!-- Task / what should the user do? -->
          <h1>{{ task.task }}</h1>

          <!-- Task Media -->
          <mediaviewer
            :media="task.Media"
            :controls="getOption(task, 'type') != 'info'"
            :autoplay="getOption(task, 'type') == 'info'"
            width="640"
            class="media"
          />

          <!-- Description -->
          <div
            v-if="task.description"
            v-html="task.description"
            class="description"
          />
        </b-container>
      </div>

      <component
        v-if="can_answer"
        :is="getOption(task, 'type') + `task`"
        :task="task"
        :round="round"
      />

      <b-container v-else>
        <b-alert variant="danger" show>{{
          $t(`only_team_captains_can_answer`)
        }}</b-alert>
      </b-container>
    </template>

    <!-- Task beantwoord -->
    <template v-else>
      <div id="task-answered">
        <b-container>
          <!-- Taak ingevoerd maar nog niet beoordeeld -->
          <template v-if="is_task_pending(task.id)">
            <b-img
              src="https://media4.giphy.com/media/Wn74RUT0vjnoU98Hnt/giphy.gif?cid=ecf05e478ip9pej69qyck5ehc5rx8gljdbxoo7ec6z0crn75&rid=giphy.gif&ct=g"
              rounded="circle"
              width="200"
              class="mb-4"
            ></b-img>
            <h5>{{ $t("thank_you") }}</h5>
            <h1>{{ $t(`thank_you_text`) }}</h1>
          </template>

          <!-- Taak afgerond (goedgekeurd of aantal pogingen op) -->
          <template v-else-if="is_task_complete(task.id)">
            <!-- Taak goedgekeurd -->
            <template v-if="is_task_approved(task.id)">
              <b-img
                src="https://media0.giphy.com/media/YRuFixSNWFVcXaxpmX/giphy.gif?cid=ecf05e47osomv1evpvgobapysijak785hk0v1izmkonwd17q&rid=giphy.gif&ct=g"
                rounded="circle"
                width="200"
                class="mb-4"
              ></b-img>
              <h5>{{ $t("well_done") }}</h5>
              <h1>{{ $t(`well_done_text`) }}</h1>
            </template>

            <!-- Taak afgekeurd & aantal pogingen op -->
            <template v-if="is_task_denied(task.id)">
              <b-img
                src="https://media1.giphy.com/media/26CaLpDvg2oDf9rAk/giphy.gif?cid=ecf05e47o5va0two0ikx3ai27o4rdjng9yqf8m0ajfomeb9y&rid=giphy.gif&ct=g"
                rounded="circle"
                width="200"
                class="mb-4"
              ></b-img>
              <h5>{{ $t("to_bad") }}</h5>
              <h1>{{ $t(`to_bad_text`) }}</h1>
            </template>
          </template>

          <b-button
            variant="primary"
            v-if="game.nav_type == 'none'"
            @click="$router.back()"
            >{{ $t("continue") }}
          </b-button>
          <b-button
            variant="primary"
            v-else-if="event_home_url != $route.path"
            @click="$router.push(event_home_url)"
            >{{ $t("continue") }}</b-button
          >
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import option from "ub-lib/mixins/option";

export default {
  name: "Task",
  data: function () {
    return {};
  },
  mixins: [option],
  computed: {
    ...mapState("socket", ["event", "game"]),
    ...mapGetters("socket", [
      "event_home_url",
      "answers_by_task_id",
      "is_task_complete",
      "is_task_pending",
      "is_task_denied",
      "is_task_approved",
      "can_answer",
      "role",
      "task_num_tries",
      "task_max_tries",
    ]),
    ...mapGetters(["isLoading"]),

    answers() {
      return this.answers_by_task_id(this.task.id);
    },

    round: {
      get() {
        return this.game.Rounds.find((r) => r.id == this.$route.params.roundId);
      },
    },
    task: {
      get() {
        return this.round.Tasks.find((t) => t.id == this.$route.params.taskId);
      },
    },
  },
  methods: {
    ...mapActions("socket", ["tick"]),
  },
  mounted() {
    this.tick();
  },
};
</script>

<template>
  <loader v-if="!$socket.connected" />

  <main class="event" v-else-if="event && game && $socket.connected">
    <!-- CHECK IF GPS IS NEEDED AND WATCHING LOCATION -->
    <template v-if="event_uses_gps && !locationWatcher">
      <b-alert :show="locationErrorMessage != ''" class="location-error-alert" variant="danger">
        <p>Huidige toestemming: {{ locationPermission }}</p>
        <p v-if="locationPermission == 'denied'">{{ $t("gps_denied_text") }}</p>
        <p>{{ locationErrorMessage }}</p>
        <b-button variant="link" @click="retryGps">{{ $t("retry_gps") }}
          <font-awesome-icon :icon="['far', 'sync']" />
        </b-button>
      </b-alert>

      <b-alert :show="!locationWatcher && locationErrorMessage == ''" class="location-error-alert" variant="danger">
        <b-spinner v-if="gettingLocation" />
        <template v-else>
          <p>{{ $t("request_gps_text") }}</p>
          <b-button variant="link" @click="requestGpsPermissions">{{ $t("request_gps_btn") }}
            <font-awesome-icon :icon="['far', 'sync']" />
          </b-button>
        </template>
      </b-alert>
    </template>

    <!-- Check for player and team -->
    <template v-if="!player ||
      (event.teams && !have_team) ||
      $socket.client.auth.uid == undefined
      ">
      <div id="event-lobby">
        <h1>{{ $t("event_error") }}</h1>
        <p>{{ $t("event_error_text") }}</p>
        <b-button to="/">{{ $t("continue") }}</b-button>
      </div>
    </template>

    <template v-else>
      <!-- Event is nog niet gestart -->
      <template v-if="!event_started && !event_ended">
        <div id="event-lobby">
          <h1>{{ $t("event_not_started") }}</h1>
          <p v-if="event.start_type == 'manager'">
            {{ $t("event_not_started_text") }}
          </p>
          <p v-else-if="event.start_type == 'time'">
            {{ $t("event_starts_at") }}
            {{ new Date(event.start_date) | formatChatDate }} om
            {{ new Date(event.start_date) | formatChatTime }}
          </p>
        </div>
      </template>

      <template v-else-if="event_ended">
        <div id="event-lobby">
          <h1>{{ $t("event_ended") }}</h1>
          <p>{{ $t("event_ended_text") }}</p>

          <router-link to="/gameoutro" class="btn btn-primary">{{
            $t("next")
          }}</router-link>
        </div>
      </template>

      <!-- Event is gestart -->
      <template v-else-if="event_started && !event_ended">
        <transition name="event-duration" mode="out-in">
          <div id="event-duration" v-if="event_started &&
            event.end_type == 'duration' &&
            !this.$route.params.taskId
            ">
            <div class="bar">
              <div class="progress" :style="{ width: timerProgress + '%' }"></div>
            </div>
            {{ timeRemaining }}
          </div>
        </transition>

        <transition :name="transition" mode="">
          <router-view id="event-view" />
        </transition>

        <!-- Tabbar niet tonen in een task -->
        <transition name="tabbar" mode="out-in">
          <div id="tabbar" v-if="!this.$route.params.taskId ||
            (this.game && this.game.nav_type != 'none')
            ">
            <router-link :to="event_home_url">
              <font-awesome-icon :icon="['far', 'tasks']" />
            </router-link>

            <router-link to="/event/highscore">
              <font-awesome-icon :icon="['far', 'trophy']" />
            </router-link>

            <router-link to="/event/help">
              <font-awesome-icon :icon="['far', 'info-circle']" />
            </router-link>

            <router-link to="/event/map" v-if="event.map_hidden != true && locationWatcher">
              <font-awesome-icon :icon="['far', 'map']" />
            </router-link>

            <router-link v-if="event.chat_hidden != true" to="/event/chat">
              <font-awesome-layers class="fa">
                <font-awesome-icon :icon="['far', 'comments']" />
                <font-awesome-icon v-if="unread_chat_messages" :style="{ color: 'red' }" transform="shrink-6 right-8 up-8"
                  :icon="['fas', 'circle']" />
              </font-awesome-layers>
            </router-link>
          </div>
        </transition>
      </template>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { formatSecondsToTime } from "ub-lib/util";
import { Geolocation } from "@capacitor/geolocation";
import option from "ub-lib/mixins/option";
import { latLng } from "leaflet";

export default {
  name: "Event",
  mixins: [option],
  data: function () {
    return {
      timer: null,
      timeRemaining: "00:00",
      timerProgress: 100,
      lastGpsUpdate: 0,

      locationWatcher: null,
      locationPermission: null,
      locationErrorMessage: "",
      gettingLocation: false,
    };
  },
  computed: {
    ...mapState(["firebase_token"]),
    ...mapState("socket", [
      "event",
      "game",
      "code",
      "players",
      "chat",
      "unread_chat_messages",
      "location",
      "playerId",
    ]),
    ...mapGetters(["isLoading", "transition"]),
    ...mapGetters("socket", [
      "has_seen_intro",
      "join",
      "event_home_url",
      "player",
      "team",
      "role",
      "player_by_id",
      "round_available",
      "task_available",
      "task_by_id",
      "round_by_id",
      "answer_by_id",
      "event_started",
      "event_ended",
      "team",
      "have_team",
      "event_time_remaining",
      "event_uses_gps",
      "gps_rounds",
      "gps_tasks",
      "active_task",
      "active_round",
    ]),

  },
  watch: {},
  async mounted() {
    if (this.firebase_token) {
      this.player.firebase_token = this.firebase_token;
      this.save_player();
    }
    this.timer = setInterval(this.updateTimeRemaining, 1000);

    this.startGps();

    if (!this.has_seen_intro) {
      this.set_intro_complete();
    }
  },
  updated() { },
  destroyed() {
    clearInterval(this.timer);

    if (this.event_uses_gps) {
      this.stopGpsWatcher();
    }
  },
  components: {},
  methods: {
    ...mapActions("socket", ["leave", "set_intro_complete", "save_player", "tick"]),

    async retryGps() {
      this.locationPermission = "";
      this.locationWatcher = null;
      this.locationErrorMessage = "";

      this.startGps();
    },

    async startGps() {
      // if (this.event_uses_gps) {
      //keep watching GPS permissions
      await this.checkGpsPermissions();

      if (this.locationPermission == "granted") {
        this.startGpsWatcher();
      }
      // }
    },

    async checkGpsPermissions() {
      const permissions = await Geolocation.checkPermissions();
      this.locationPermission = permissions.location;
    },

    async stopGpsWatcher() {
      if (this.locationWatcher) {
        try {
          Geolocation.clearWatch(this.locationWatcher);
        } catch (e) {
          console.log(e);
        }
        this.locationWatcher = null;
      }
    },

    async startGpsWatcher() {
      this.stopGpsWatcher();
      await Geolocation.watchPosition(
        { enableHighAccuracy: true, timeout: 1000, maximumAge: 0 },
        this.locationChanged
      )
        .catch((err) => {
          console.log("watch position error", err);
          this.stopGpsWatcher();
          this.locationErrorMessage = err.message;
          this.gettingLocation = false;
        })
        .then((result) => {
          console.log("watch position success", result);
          this.locationWatcher = result;
        });
    },

    async requestGpsPermissions() {
      this.gettingLocation = true;

      await this.checkGpsPermissions();

      await Geolocation.getCurrentPosition()
        .catch((err) => {
          console.log("get position error", err);
          this.stopGpsWatcher();
          this.locationErrorMessage = err.message;
          this.gettingLocation = false;
        })
        .then((coordinates) => {
          if (coordinates) {
            this.locationErrorMessage = null;
            this.$store.dispatch("socket/update_location", coordinates);

            this.startGpsWatcher();
          }

          this.gettingLocation = false;
        });
    },

    updateTimeRemaining() {
      this.timeRemaining = formatSecondsToTime(this.event_time_remaining());
      this.timerProgress =
        100 - (this.event_time_remaining() / (this.event.duration * 60)) * 100;
    },

    async locationChanged(coordinates, error) {
      const start = new Date().getTime();
      if (coordinates && start - this.lastGpsUpdate > 1000) {
        //max 1x per second
        this.lastGpsUpdate = start;

        //send player position to server
        this.$store.dispatch("socket/update_location", coordinates);

        //player location
        let playerLatLng = new latLng(
          coordinates.coords.latitude,
          coordinates.coords.longitude
        );

        //only unlock tasks when the event is started
        if (this.event_started) {
          //go over all rounds
          for (const round of this.gps_rounds.filter(
            (r) => !this.round_available(r.id)
          )) {
            let roundPosition = this.getOption(round, "latlng");
            let roundLatLng = new latLng(
              roundPosition.latlng.lat,
              roundPosition.latlng.lng
            );
            let distance = playerLatLng.distanceTo(roundLatLng);

            //if we're in radius of the round, unlock it
            if (distance < roundPosition.radius) {
              this.$store.dispatch("socket/unlock_gps_round", round.id);
            }
          }

          //go over all tasks
          for (const task of this.gps_tasks.filter(
            (t) => !this.task_available(t.id)
          )) {
            let taskPosition = this.getOption(task, "latlng");
            let taskLatLng = new latLng(
              taskPosition.latlng.lat,
              taskPosition.latlng.lng
            );
            let distance = playerLatLng.distanceTo(taskLatLng);

            //if we're in radius of the task, unlock it
            if (distance < taskPosition.radius) {
              this.$store.dispatch("socket/unlock_gps_task", task.id);
            }
          }
        }
      } else {
        console.log(`No coordinates`, error);
      }
    },

    check_nav_type() {
      if (this.game && this.game.nav_type && this.game.nav_type != "none") {
        if (this.$route.path != this.event_home_url) {
          this.$router.replace(this.event_home_url);
        }
      }
    },
  },
  sockets: {
    // TRIGGERED WHEN A 'EXTRA' IS REMOVED FROM A PLAYER
    event_extra_removed(extra) {
      if (extra.playerId == this.player.id) {
        // Use a shorter name for this.$createElement
        const h = this.$createElement;

        // Create the message
        const vNodesMsg = h("p", [
          h("p", `${extra.description}`),
          h("mediaviewer", {
            props: {
              media: extra.Image,
            },
          }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          // { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [h("strong", { class: "mr-2" }, `Lost Badge: ${extra.title}`)]
        );
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          variant: "danger",
        });
      }
    },
    // TRIGGERED WHEN A 'EXTRA' IS ADDED TO A PLAYER
    event_extra(extra) {
      if (extra.playerId == this.player.id) {
        // Use a shorter name for this.$createElement
        const h = this.$createElement;

        // Create the message
        const vNodesMsg = h("p", [
          h("p", `${extra.description}`),
          h("mediaviewer", {
            props: {
              media: extra.Image,
            },
          }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          // { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [h("strong", { class: "mr-2" }, `Received Badge: ${extra.title}`)]
        );
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          variant: "success",
        });
      }
    },
    //TRIGGERED WHEN A PLAYER ANSWER IS 'UPDATED' (OR CREATED)
    answer_updated(answer) {
      this.tick();
      //get the associated task
      let task = this.task_by_id(answer.taskId);

      //get the local answer. if it doesn't exist, we can assume it was just submitted by ourself
      //otherwise it is probably updated by the manager
      let localAnswer = this.answer_by_id(answer.id);
      if (localAnswer) {
        // Use a shorter name for this.$createElement
        const h = this.$createElement;

        var title, body, variant;
        switch (answer.status) {
          case "pending":
            title = this.$t("pending");
            body = task.title + " " + this.$t("is_pending") + ".";
            variant = "warning";
            break;
          case "approved":
            title = this.$t("well_done");
            body =
              task.title +
              " " +
              this.$t("is_approved") +
              "! " +
              answer.points +
              " " +
              this.$t("points") +
              "!";
            variant = "success";
            break;
          case "denied":
            title = this.$t("to_bad");
            body = task.title + " " + this.$t("is_denied") + ".";
            variant = "danger";
            break;
        }
        if (answer.AnswerFeedback) {
          title = answer.AnswerFeedback.title;
        }

        let bodyElements = [];
        bodyElements.push(h("p", `${body}`));

        if (answer.manager_remarks) {
          bodyElements.push(h("p", `${answer.manager_remarks}`));
        }

        //answerfeedback
        if (answer.AnswerFeedback) {
          bodyElements.push(h("p", `${answer.AnswerFeedback.description}`));
          if (answer.AnswerFeedback.Media) {
            bodyElements.push(
              h("mediaviewer", {
                props: {
                  media: answer.AnswerFeedback.Media,
                },
              })
            );
          }
        }

        // Create the message
        const vNodesMsg = h("p", bodyElements);
        // Create the title
        const vNodesTitle = h("div", [
          h("strong", { class: "mr-2" }, `${title}`),
        ]);
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          autoHideDelay: 5000,
          appendToast: false,
          variant: variant,
        });
      }
    },
    //TRIGGERED WHEN A ROUND IS UNLOCKED
    round_unlocked(roundId) {
      this.tick();
      let round = this.round_by_id(roundId);

      if (round) {
        // Use a shorter name for this.$createElement
        const h = this.$createElement;

        // Create the message
        const vNodesMsg = h("p", [
          h("p", `${this.$t("round_unlocked_msg", { round: round.title })}`),
          h("mediaviewer", {
            props: {
              media: round.Media,
            },
          }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          // { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h(
              "strong",
              { class: "mr-2" },
              `${this.$t("round_unlocked_title", { round: round.title })}`
            ),
          ]
        );
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          variant: "success",
        });
      }
    },
    //TRIGGERED WHEN A ROUND IS LOCKED
    round_locked(roundId) {
      this.tick();
      console.log(roundId);
    },

    //TRIGGERED WHEN A ROUND IS UNLOCKED
    task_unlocked(taskId) {
      this.tick();
      let task = this.task_by_id(taskId);

      if (task) {
        // Use a shorter name for this.$createElement
        const h = this.$createElement;

        // Create the message
        const vNodesMsg = h("p", [
          h("p", `${this.$t("task_unlocked_msg", { task: task.title })}`),
          h("mediaviewer", {
            props: {
              media: task.Media,
            },
          }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          // { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h(
              "strong",
              { class: "mr-2" },
              `${this.$t("task_unlocked_title", { task: task.title })}`
            ),
          ]
        );
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          variant: "success",
        });
      }
    },

    game() {
      this.$nextTick(() => this.check_nav_type());
    },
  },
};
</script>

<template>
  <main class="gameinfo" v-if="event && game">
    <b-container>
      <div class="img-holder" v-if="game && game.Logo">
        <mediaviewer :media="game.Logo" width="300" />
      </div>

      <h1>{{ game.title }}</h1>
      <div v-html="game.description"></div>

      <b-button variant="primary" :to="`/whoareyou`">{{ $t('start') }}</b-button>

    </b-container>

  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'GameDescription',
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('socket', ['event', 'code', 'playerId', 'players', 'chat', 'game']),
    ...mapGetters(['isLoading', 'transition']),
    ...mapGetters('socket', ['player', 'player_by_id', 'has_seen_intro']),
  },
  watch: {
  },
  async mounted() {
  },
  components: {
  },
  methods: {

  }
}
</script>

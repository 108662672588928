<template>
  <div class="highscores">

    <eventheader :title="$t('highscores')" />
    
    <div id="scroll-container">
      <b-container>
        <highscore-table />
      </b-container>
    </div>
    
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Highscores',
  data: function() {
    return {
      highscore_fields: [
          { key:"rank", label: "" },
          { key:"name", label: "" },
          { key:"score", label: "" },
          { key:"badges", label: "" },
      ],
    }
  },
  computed: {
    ...mapState('socket', ['event', 'code', 'players', 'chat', 'highscores']),
    ...mapGetters(['isLoading']),
    ...mapGetters('socket', ['player', 'player_by_id', 'team_by_id', 'player_badges']),
  },
  watch: {
  },
  async mounted() {
  },
  components: { },
  methods: {
  }
}
</script>

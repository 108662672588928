<template>
  <div id="task-content" class="task-open">
    
    <!-- Text input -->
    <b-container>
      <b-form @submit.prevent="">
        <b-form-group :label="$t('your_answer')">
          <b-form-input v-model="answer" required />
        </b-form-group>
      </b-form>

      <!-- Error message in case something went wrong -->
      <b-alert variant="danger" :show="message != ''">{{message}}</b-alert>

      <!-- Submit answer button -->
      <b-button v-if="!loading" :disabled="btnSubmitEnabled" variant="primary" @click="submitAnswer">{{$t('send')}}</b-button>
      <loader v-else />

    </b-container>
    
  </div>
</template>
<script>
import task from '../../mixins/task'

export default {
  name: 'OpenTask',
  mixins: [ task ]
}
</script>